var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Mesh, RawShaderMaterial, Vector2 } from 'three';
import vertexShader from './glsl/kvMainImage.vert';
import fragmentShader from './glsl/kvMainImage.frag';
import { Linear, SimpleTween } from '../libs/SimpleTween';
import { COMMON_PLANE_GEOMETRY_SIZE } from './KVGL';
import KVMainImageShoeEffect from './KVMainImageShoeEffect';
export default class KVMainImage extends Mesh {
    constructor(commonPlaneGeometry, mainImageNoiseTexture) {
        super(commonPlaneGeometry, new RawShaderMaterial({
            transparent: true,
            vertexShader,
            fragmentShader,
            depthWrite: false,
            uniforms: {
                time: { value: 0 },
                animationValue0: { value: 0 },
                animationValue1: { value: 0 },
                resolution: { value: new Vector2(1, 1) },
                texture: { value: null },
                noiseTexture: { value: mainImageNoiseTexture },
                uvOffset: { value: new Vector2() },
                uvSize: { value: new Vector2(1, 1) },
                uvPadding: { value: new Vector2(0, 0) },
                binaryThreshold: { value: 0.84 },
                posterizationThreshold: { value: 1.85 },
                effectCenter: { value: new Vector2() }
            }
        }));
        this.animationValue0Tween = null;
        this.animationValue1Tween = null;
        this.pointerPos = new Vector2();
        this.pointerMoveSize = new Vector2();
        this.offset = new Vector2();
        this.frustumCulled = true;
        this.matrixAutoUpdate = false;
        this.noiseTexture = mainImageNoiseTexture;
    }
    initShoeEffect(shoeEffectData) {
        this.shoeEffect = new KVMainImageShoeEffect(this.geometry);
        this.shoeEffect.applyData(shoeEffectData, this.noiseTexture);
        this.add(this.shoeEffect);
    }
    setTexture(texture) {
        const material = this.material;
        material.uniforms.texture.value = texture;
        this.shoeEffect.setTexture(texture);
    }
    setUVSize(uvSize) {
        const material = this.material;
        material.uniforms.uvSize.value = uvSize;
    }
    setUVOffset(uvOffset) {
        const material = this.material;
        material.uniforms.uvOffset.value = uvOffset;
    }
    reset() {
        var _a, _b;
        const material = this.material;
        material.uniforms.animationValue0.value = 0;
        material.uniforms.animationValue1.value = 0;
        (_a = this.animationValue0Tween) === null || _a === void 0 ? void 0 : _a.kill();
        this.animationValue0Tween = null;
        (_b = this.animationValue1Tween) === null || _b === void 0 ? void 0 : _b.kill();
        this.animationValue1Tween = null;
        this.shoeEffect.reset();
    }
    setNoiseTexture(noiseTexture) {
        const material = this.material;
        material.uniforms.noiseTexture.value = noiseTexture;
    }
    applyParams(width, height, offsetX, offsetY, rotation, containerWidth, containerHeight, effectCenter) {
        const material = this.material;
        material.uniforms.resolution.value.x = width;
        material.uniforms.resolution.value.y = height;
        material.uniforms.effectCenter.value = effectCenter;
        this.scale.set(width / COMMON_PLANE_GEOMETRY_SIZE, height / COMMON_PLANE_GEOMETRY_SIZE, 1);
        this.rotation.z = rotation;
        this.offset.x = offsetX;
        this.offset.y = offsetY;
        const pointerMoveRatio = 0.1;
        this.pointerMoveSize.x = containerWidth * pointerMoveRatio;
        this.pointerMoveSize.y = containerHeight * pointerMoveRatio;
        this.updatePos();
    }
    updatePos() {
        this.position.x =
            this.offset.x + this.pointerMoveSize.x * this.pointerPos.x;
        this.position.y =
            this.offset.y + this.pointerMoveSize.y * this.pointerPos.y;
        this.updateMatrix();
        this.shoeEffect.updateMatrix();
    }
    update(time) {
        const material = this.material;
        material.uniforms.time.value = time;
        this.shoeEffect.update(time);
    }
    animateAnimationValue0({ value = 0, duration = 3, delay = 0, onComplete = () => { }, ease = Linear.easeNone }) {
        return new Promise((resolve) => {
            const material = this.material;
            this.animationValue0Tween = new SimpleTween(material.uniforms.animationValue0, {
                delay,
                duration,
                value,
                onComplete: () => {
                    onComplete();
                    resolve('');
                },
                ease
            });
        });
    }
    animateAnimationValue1({ value = 0, duration = 3, delay = 0, onComplete = () => { }, ease = Linear.easeNone }) {
        return new Promise((resolve) => {
            const material = this.material;
            this.animationValue1Tween = new SimpleTween(material.uniforms.animationValue1, {
                delay,
                duration,
                value,
                onComplete: () => {
                    onComplete();
                    resolve('');
                },
                ease
            });
        });
    }
    show(delay = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            this.shoeEffect.show(delay + 1.6);
            this.animateAnimationValue0({ value: 1, delay, duration: 3.4 });
            return this.animateAnimationValue1({
                value: 1,
                delay: delay + 0.4,
                duration: 3
            });
        });
    }
    hide(delay = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            this.animateAnimationValue0({ value: 0, delay, duration: 1.2 });
            return this.animateAnimationValue1({ value: 0, delay, duration: 1.2 });
        });
    }
    initDebugPanel(folder) {
        const material = this.material;
        const params = {
            animationValue0: material.uniforms.animationValue0.value,
            animationValue1: material.uniforms.animationValue1.value,
            binaryThreshold: material.uniforms.binaryThreshold.value,
            posterizationThreshold: material.uniforms.posterizationThreshold.value
        };
        this.tweakPaneFolder = folder.addFolder({ title: 'mainImage' });
        this.tweakPaneFolder
            .addInput(params, 'animationValue0', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.animationValue0.value = e.value;
        });
        this.tweakPaneFolder
            .addInput(params, 'animationValue1', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.animationValue1.value = e.value;
        });
        this.tweakPaneFolder
            .addInput(params, 'binaryThreshold', { min: 0, max: 1 })
            .on('change', (e) => {
            material.uniforms.binaryThreshold.value = e.value;
        });
        this.tweakPaneFolder
            .addInput(params, 'posterizationThreshold', { min: 0, max: 10 })
            .on('change', (e) => {
            material.uniforms.posterizationThreshold.value = e.value;
        });
        this.shoeEffect.initDebugPanel(folder);
    }
    setPointerPos(pointerPos) {
        this.pointerPos = pointerPos;
        this.updatePos();
    }
}
